import React from "react";
import { useGlobalContext } from "../../context/GlobalContext";
import { useNavigate } from 'react-router-dom';

import FooterEndereco from "./FooterEndereco";

import "./Footer.css";

const Footer = () => {
  const navigate = useNavigate();
  const { city } = useGlobalContext();

  const handleButtonClick = (value) => {
    console.log(value);
    switch (value) {
      case "Consultar viabilidade":
      case "Cobertura":
        window.open("https://central.itnettelecom.com.br/viabilidade-tecnica.php", "_blank")
        break;
      case "Planos":
        setTimeout(() => window.location.href = "#planosContainer", 300);
        break;
      case "Dúvidas Frequentes":
        navigate("/duvidas");
        break;
      case "Testar conexão":
        window.open("http://ixcsoft.speedtestcustom.com/", "_blank");
        break;
      case "App para Android":
        window.open("https://play.google.com/store/apps/details?id=sac.itnettelecom.com.br&hl=pt_BR&gl=US", "_blank");
        break;
      case "App para iOS":
        window.open("https://apps.apple.com/no/app/itnet-telecom-sac/id1489219685?l=pt" , "_blank");
        break;
      default:
        navigate(`/${value.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`);
        break;
    }
  }
  return (
    <div className="footerContainer">
      <div className="footerContainerButtons">
        <div className="footerMenu">
          <div className="footerTitle">MENU</div>
          <div
            className="footerButton"
            onClick={(e) => handleButtonClick(e.target.innerText)}
          >
            Cobertura
          </div>
          <div
            className="footerButton"
            onClick={(e) => handleButtonClick(e.target.innerText)}
          >
            Planos
          </div>
          <div
            className="footerButton"
            onClick={(e) => handleButtonClick(e.target.innerText)}
          >
            Dúvidas Frequentes
          </div>
        </div>
        <div className="footerServicos">
          <div className="footerTitle">SERVIÇOS</div>
          <div
            className="footerButton"
            onClick={(e) => handleButtonClick(e.target.innerText)}
          >
            Consultar viabilidade
          </div>
          <div
            className="footerButton"
            onClick={(e) => handleButtonClick(e.target.innerText)}
          >
            Testar conexão
          </div>
          <div
            className="footerButton"
            onClick={(e) => handleButtonClick(e.target.innerText)}
          >
            App para Android
          </div>
          <div
            className="footerButton"
            onClick={(e) => handleButtonClick(e.target.innerText)}
          >
            App para iOS
          </div>
        </div>
        <div className="footerContato">
          <div className="footerTitle">CONTATO</div>
          <div>
            {city.email ? (
              <span>
                E-mail <br /> {city.email}
              </span>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="footerEndereco">
        <FooterEndereco />
      </div>
      <div className="auxFooter"></div>
    </div>
  );
};
export default Footer;
