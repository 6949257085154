import React from "react";
import Home from "../Pages/Home/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PaginaInicial from "../Pages/PaginaInicial";
import Empresa from "../Pages/Empresa";
import Duvidas from "../Pages/Duvidas";
import Contato from "../Pages/Contato";
import Checkout from "../Pages/Checkout";
import NotFound from "../Pages/NotFound";
import Funcionario from "../Pages/Funcionario";
import Canais from "../Pages/Canais";
import { cidades } from "../Utils/Cidades";
import Telefone from "../components/Contato/Telefone";
import Whatsapp from "../components/Contato/Whatsapp";

function AppRoutes() {

  //return <></>;

  return (
    <BrowserRouter>
      <Routes>
        <Route index path="/" element={<PaginaInicial city={cidades.itabaiana}/>} />
        {/* <Route path="/itabaiana" element={<PaginaInicial city={cidades.itabaiana}/>} />
        <Route path="/riachuelo" element={<PaginaInicial city={cidades.riachuelo}/>} />
        <Route path="/santa-rosa-de-lima" element={<PaginaInicial city={cidades.srLima}/>} />
        <Route path="/nossa-sr-das-dores" element={<PaginaInicial city={cidades.nsDores}/>} />
        <Route path="/nossa-sr-da-gloria" element={<PaginaInicial city={cidades.nsGloria}/>} />
        <Route path="/feira-nova" element={<PaginaInicial city={cidades.feiraNova}/>} />
        <Route path="/ribeiropolis" element={<PaginaInicial city={cidades.ribeiropolis}/>} />
        <Route path="/cumbe" element={<PaginaInicial city={cidades.cumbe}/>} />
        <Route path="/graccho-cardoso" element={<PaginaInicial city={cidades.gracho}/>} /> */}
        {/*<Route path="/Empresa" element={<Empresa />} />*/}
        <Route path="/duvidas" element={<Duvidas />} />
        <Route path="/contato" element={<Contato />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/funcionario" element={<Funcionario />} />
        <Route path="/canais" element={<Canais />} />
        <Route path="/telefone" element={<Telefone />} />
        <Route path="/whatsapp" element={<Whatsapp />} />
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;
